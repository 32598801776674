import {
  type ClientLoaderFunctionArgs,
  redirect,
  useLoaderData,
} from '@remix-run/react';
import { useTitle } from 'react-use';
import { $path } from 'remix-routes';

import { OTPUtils } from '../components/OneTimePurchase';
import { OTPCheckoutSchedulePlanningCall } from '../components/OneTimePurchase/OTPCheckoutScheduledPlanningCall';
import { makeTitle } from '../utils/common';
import { useCheckoutLoaderData } from './checkout.client';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const url = new URL(action.request.url);
  const headcount = OTPUtils.ParseHeadcount(url.searchParams.get('headcount'));
  if (headcount <= 10) {
    return redirect($path('/checkout/completed', url.search));
  }
  return {
    headcount,
  };
};

export function Component() {
  const { pack } = useCheckoutLoaderData();
  const { headcount } = useLoaderData<typeof clientLoader>();

  useTitle(makeTitle(`Schedule Event Planning Call | Purchase ${pack.name}`));

  return <OTPCheckoutSchedulePlanningCall pack={pack} headcount={headcount} />;
}
