import { useNavigate } from '@remix-run/react';
import { $path } from 'remix-routes';

import { type DtoGamePack } from '@lp-lib/api-service-client/public';

import { useOneTimePurchaseAnalytics } from '../../analytics/oneTimePurchase';
import config from '../../config';
import { Tooltip } from '../common/Tooltip';
import { QuestionMarkIcon } from '../icons/QuestionMarkIcon';
import { HubspotMeetingsForm } from '../Marketing/HubspotMeetingsForm';
import { OTPCheckoutLayout } from './OTPCheckoutLayout';
import { OTPUtils } from './utils';

export function OTPCheckoutSchedulePlanningCall(props: {
  pack: DtoGamePack;
  headcount: number;
}) {
  const { pack, headcount } = props;

  const navigate = useNavigate();
  const analytics = useOneTimePurchaseAnalytics();

  const handleSubmit = () => {
    analytics.trackEventPurchaseEventPlanningCallScheduled({
      type: OTPUtils.GamePackType(pack),
    });

    setTimeout(() => {
      navigate($path('/checkout/completed', window.location.search));
    }, 3000);
  };

  const hubspotUrl = new URL(config.misc.eventPlanningHubspotUrl);
  hubspotUrl.searchParams.set('team_size', headcount.toString());

  return (
    <OTPCheckoutLayout
      pack={pack}
      progress={60}
      background={false}
      containerClassName='!w-full h-full !items-start'
    >
      <div className='w-full h-full'>
        <div className='w-full h-12 bg-tertiary text-xl font-bold text-black flex justify-center items-center'>
          Don’t skip this step! Scheduling an event planning session is
          <span className='ml-1 underline'>required</span>!
          <div className='relative ml-1 group flex justify-center'>
            <QuestionMarkIcon className='w-4 h-4 fill-current' />
            <div className='absolute invisible group-hover:visible z-5 bottom-full -mb-1'>
              <Tooltip
                position={'top'}
                backgroundColor='black'
                borderRadius={12}
                arrowWidth={12}
                borderColor={'rgba(255, 255, 255, 0.4)'}
                borderWidth={1}
              >
                <p className='w-70 p-3 text-3xs text-white'>
                  We’ve found that this quick event planning call is the best
                  way to ensure your event is a success
                </p>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className='w-full p-2 flex justify-center gap-12 text-white'>
          <div className='w-90 pt-4'>
            <h2 className='text-xl font-bold text-lp-gray-001'>
              Thank you for your purchase!
            </h2>
            <p className='mt-4 text-base text-icon-gray'>
              As part of your purchase, you have access to an account manager
              who will ensure your event runs smoothly.
            </p>
            <div className='mt-5 w-full bg-dark-gray rounded-1.5lg px-5 py-4'>
              <p className=' text-green-001 font-bold'>
                Schedule a short call to get everything set up for a successful
                event!
              </p>
              <ul className='mt-8 list-disc list-inside text-sms'>
                <li>A 30 minute Zoom call</li>
                <li>Get help with the calendar invite for your event</li>
                <li>Luna Park walkthrough + FAQs</li>
                <li>Feel free to bring a colleague</li>
              </ul>
            </div>
          </div>

          <HubspotMeetingsForm
            title='Schedule Your Event Planning Call'
            src={hubspotUrl.toString()}
            className='w-210 h-180'
            onSubmitted={handleSubmit}
          />
        </div>
      </div>
    </OTPCheckoutLayout>
  );
}
